import {
  FACIAL_MANDIBULAR_ANGLE,
  INCISOR_OVERLAP, OVERJET_DISTANCE,
  ST_PG_TO_VERTICAL_DISTANCE,
  TREATMENT_TYPE,
  UPPER_TOOTH_SHOWING,
} from './input-values';

export const determineChecklist = ({
  treatmentType,
  upperToothShowing,
  incisorOverlap,
  stPgToVerticalDistance,
  facialMandibularAngle,
  overjetDistance,
  isSurgeryNeeded,
}) => {
  if (treatmentType === TREATMENT_TYPE.OVERBITE_UNDERBITE_CORRECTION) {
    if (upperToothShowing === UPPER_TOOTH_SHOWING.LTE_100_GT_75) {
      if (incisorOverlap === INCISOR_OVERLAP.LTE_50_GT_10) {
        return 1;
      } else if (incisorOverlap === INCISOR_OVERLAP.GT_50) {
        return 2;
      } else if (incisorOverlap === INCISOR_OVERLAP.LTE_10_GT_N10) {
        return 3;
      } else if (incisorOverlap === INCISOR_OVERLAP.LTE_N10) {
        return 4;
      }
    } else if (upperToothShowing === UPPER_TOOTH_SHOWING.LTE_75_GT_50) {
      if ([INCISOR_OVERLAP.LTE_50_GT_10, INCISOR_OVERLAP.GT_50].includes(incisorOverlap)) {
        return 5;
      } else if (incisorOverlap === INCISOR_OVERLAP.LTE_10_GT_N10) {
        return 6;
      } else if (incisorOverlap === INCISOR_OVERLAP.LTE_N10) {
        return 7;
      }
    } else if (upperToothShowing === UPPER_TOOTH_SHOWING.LTE_50) {
      if ([INCISOR_OVERLAP.LTE_50_GT_10, INCISOR_OVERLAP.GT_50].includes(incisorOverlap)) {
        return 8;
      } else if (incisorOverlap === INCISOR_OVERLAP.LTE_10_GT_N10) {
        return 9;
      } else if (incisorOverlap === INCISOR_OVERLAP.LTE_N10) {
        return 10;
      }
    }
  } else if (treatmentType === TREATMENT_TYPE.OVERJET) {
    if (overjetDistance === OVERJET_DISTANCE.LT_3) {
      return 11;
    } else if (overjetDistance === OVERJET_DISTANCE.GTE_3) {
      if (facialMandibularAngle === FACIAL_MANDIBULAR_ANGLE.LTE_26) {
        return 11;
      } else if (facialMandibularAngle === FACIAL_MANDIBULAR_ANGLE.GT_26) {
        return 12;
      }
    }
  } else if (treatmentType === TREATMENT_TYPE.MANDIBULAR_RETROGNATHIA_PROGNATHIA) {
    if (stPgToVerticalDistance === ST_PG_TO_VERTICAL_DISTANCE.LTE_N3) {
      if (facialMandibularAngle === FACIAL_MANDIBULAR_ANGLE.LTE_26) {
        return 13;
      } else if (facialMandibularAngle === FACIAL_MANDIBULAR_ANGLE.GT_26) {
        return 14;
      }
    } else if (stPgToVerticalDistance === ST_PG_TO_VERTICAL_DISTANCE.GT_0) {
      if (facialMandibularAngle === FACIAL_MANDIBULAR_ANGLE.LTE_26) {
        return 15;
      } else if (facialMandibularAngle === FACIAL_MANDIBULAR_ANGLE.GT_26) {
        if (isSurgeryNeeded === true) {
          return 16;
        } else if (isSurgeryNeeded === false) {
          return 17;
        }
      }
    }
  }
}
import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Unstable_Grid2';

import { FACIAL_MANDIBULAR_ANGLE, OVERJET_DISTANCE } from './input-values';


export const OverjetQuestions =
  ({ overjetDistance, setOverjetDistance, facialMandibularAngle, setFacialMandibularAngle }) => {
    return (
      <>
        <Grid xs={12}>
          <FormControl>
            <FormLabel id="st-pg-to-vertical-distance">What is the overjet distance (assuming deep overbite >30%)?</FormLabel>
            <RadioGroup
              aria-labelledby="st-pg-to-vertical-distance"
              onChange={(event) => setOverjetDistance(event.target.value)}
            >
              <FormControlLabel value={OVERJET_DISTANCE.LT_3} control={<Radio checked={overjetDistance === OVERJET_DISTANCE.LT_3} />} label="x < 3mm (e.g. 2mm)"/>
              <FormControlLabel value={OVERJET_DISTANCE.GTE_3} control={<Radio checked={overjetDistance === OVERJET_DISTANCE.GTE_3} />} label="x ≥ 3mm (e.g. 5mm)"/>
            </RadioGroup>
          </FormControl>
        </Grid>
        {overjetDistance === OVERJET_DISTANCE.GTE_3 && <Grid xs={12}>
          <FormControl>
            <FormLabel id="facial-mandibular-angle">What Is the facial mandibular angle?</FormLabel>
            <RadioGroup
              aria-labelledby="facial-mandibular-angle"
              onChange={(event) => setFacialMandibularAngle(event.target.value)}
            >
              <FormControlLabel value={FACIAL_MANDIBULAR_ANGLE.LTE_26} control={<Radio checked={facialMandibularAngle === FACIAL_MANDIBULAR_ANGLE.LTE_26} />} label="x ≤ 26º (e.g. 20º)"/>
              <FormControlLabel value={FACIAL_MANDIBULAR_ANGLE.GT_26} control={<Radio checked={facialMandibularAngle === FACIAL_MANDIBULAR_ANGLE.GT_26} />} label="x > 26º (e.g. 30º)"/>
            </RadioGroup>
          </FormControl>
        </Grid>}
      </>
    )
  };
import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Unstable_Grid2';

import { FACIAL_MANDIBULAR_ANGLE, ST_PG_TO_VERTICAL_DISTANCE } from './input-values';


export const MandibularQuestions =
  ({
     stPgToVerticalDistance,
     setStPgToVerticalDistance,
     facialMandibularAngle,
     setFacialMandibularAngle,
     isSurgeryNeeded,
     setIsSurgeryNeeded,
  }) => {
  const shouldDisplayIsSurgeryNeeded = stPgToVerticalDistance === ST_PG_TO_VERTICAL_DISTANCE.GT_0 &&
    facialMandibularAngle === FACIAL_MANDIBULAR_ANGLE.GT_26;

    return (
      <>
        <Grid xs={12}>
          <FormControl>
            <FormLabel id="st-pg-to-vertical-distance">What is the distance from  ST-PG to vertical perpendicular?</FormLabel>
            <RadioGroup
              aria-labelledby="st-pg-to-vertical-distance"
              onChange={(event) => setStPgToVerticalDistance(event.target.value)}
            >
              <FormControlLabel value={ST_PG_TO_VERTICAL_DISTANCE.LTE_N3} control={<Radio checked={stPgToVerticalDistance === ST_PG_TO_VERTICAL_DISTANCE.LTE_N3} />} label="x ≤ -3mm (e.g. -4mm)"/>
              <FormControlLabel value={ST_PG_TO_VERTICAL_DISTANCE.GT_0} control={<Radio checked={stPgToVerticalDistance === ST_PG_TO_VERTICAL_DISTANCE.GT_0} />} label="x > 0mm (e.g. 2mm)"/>
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <FormControl>
            <FormLabel id="facial-mandibular-angle">What Is the facial mandibular angle?</FormLabel>
            <RadioGroup
              aria-labelledby="facial-mandibular-angle"
              onChange={(event) => setFacialMandibularAngle(event.target.value)}
            >
              <FormControlLabel value={FACIAL_MANDIBULAR_ANGLE.LTE_26} control={<Radio checked={facialMandibularAngle === FACIAL_MANDIBULAR_ANGLE.LTE_26} />} label="x ≤ 26º (e.g. 20º)"/>
              <FormControlLabel value={FACIAL_MANDIBULAR_ANGLE.GT_26} control={<Radio checked={facialMandibularAngle === FACIAL_MANDIBULAR_ANGLE.GT_26} />} label="x > 26º (e.g. 30º)"/>
            </RadioGroup>
          </FormControl>
        </Grid>
        {shouldDisplayIsSurgeryNeeded && <Grid xs={12}>
          <FormControl>
            <FormLabel id="facial-mandibular-angle">Is surgery necessary?</FormLabel>
            <RadioGroup
              aria-labelledby="facial-mandibular-angle"
              onChange={(event) => setIsSurgeryNeeded(event.target.value === "true")}
            >
              <FormControlLabel value={true} control={<Radio checked={isSurgeryNeeded === true} />} label="Yes"/>
              <FormControlLabel value={false} control={<Radio checked={isSurgeryNeeded === false} />} label="No"/>
            </RadioGroup>
          </FormControl>
        </Grid>}
      </>
    )
  };
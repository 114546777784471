/**
 * Naming convention for ranges:
 * GT_<number> = Greater Than <number> (e.g. > 4)
 * GTE_<number> = Greater Than or Equal to <number> (e.g. ≥ 4)
 * GTE_N<number> = Greater Than or Equal to negative <number> (e.g. ≥ -4)
 * LT_<number> = Less Than <number> (e.g. < 4)
 * LTE_<number> = Less Than or Equal to <number> (e.g. ≤ 4)
 * LTE_N<number> = Less Than or Equal to negative <number> (e.g. ≤ -4)
 *
 * LTE_0_GT_N10 would read as "Less Than or Equal to 0 and Greater Than -10"
 */
export const TREATMENT_TYPE = {
  OVERBITE_UNDERBITE_CORRECTION: 'OVERBITE_UNDERBITE_CORRECTION',
  MANDIBULAR_RETROGNATHIA_PROGNATHIA: 'MANDIBULAR_RETROGNATHIA_PROGNATHIA',
  OVERJET: 'OVERJET',
};

export const UPPER_TOOTH_SHOWING = {
  LTE_100_GT_75: 'LTE_100_GT_75',
  LTE_75_GT_50: 'LTE_75_GT_50',
  LTE_50: 'LTE_50',
};

export const INCISOR_OVERLAP = {
  GT_50: 'GT_50',
  LTE_50_GT_10: 'LTE_50_GT_10',
  LTE_10_GT_N10: 'LTE_10_GT_N10',
  LTE_N10: 'LTE_N10',
};

export const ST_PG_TO_VERTICAL_DISTANCE = {
  GT_0: 'GTE_0',
  LTE_N3: 'LT_N3',
};

export const FACIAL_MANDIBULAR_ANGLE = {
  GT_26: 'GT_26',
  LTE_26: 'LTE_26',
};

export const OVERJET_DISTANCE = {
  GTE_3: 'GTE_3',
  LT_3: 'LT_3',
};
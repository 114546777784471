import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {
  TREATMENT_TYPE,
} from './input-values';
import { OverbiteUnderbiteQuestions } from './OverbiteUnderbiteQuestions';
import { MandibularQuestions } from './MandibularQuestions';
import { OverjetQuestions } from './OverjetQuestions';
import { determineChecklist } from './determine-checklist';
import { AlignerChecklistDescription } from './AlignerChecklistDescription';

const StyledPaper = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

export function Home() {
  const [showChecklist, setShowChecklist] = useState(false);
  const [currentChecklist, setCurrentChecklist] = useState(0);

  const [treatmentType, setTreatmentType] = useState();

  const [upperToothShowing, setUpperToothShowing] = useState();
  const [incisorOverlap, setIncisorOverlap] = useState();

  const [stPgToVerticalDistance, setStPgToVerticalDistance] = useState();
  const [facialMandibularAngle, setFacialMandibularAngle] = useState();

  const [isSurgeryNeeded, setIsSurgeryNeeded] = useState();

  const [overjetDistance, setOverjetDistance] = useState();

  const switchTreatmentType = (event) => {
    setUpperToothShowing(undefined);
    setStPgToVerticalDistance(undefined);
    setFacialMandibularAngle(undefined);
    setOverjetDistance(undefined);
    setTreatmentType(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const suitableChecklist = determineChecklist({
      treatmentType,
      upperToothShowing,
      incisorOverlap,
      stPgToVerticalDistance,
      facialMandibularAngle,
      overjetDistance,
      isSurgeryNeeded,
    });
    if (suitableChecklist !== undefined) {
      setCurrentChecklist(suitableChecklist);
      setShowChecklist(true);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StyledPaper>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="treatment-type">Treatment type</FormLabel>
                <RadioGroup
                  aria-labelledby="treatment-type"
                  onChange={switchTreatmentType}
                >
                  <FormControlLabel
                    value={TREATMENT_TYPE.OVERBITE_UNDERBITE_CORRECTION}
                    control={<Radio checked={treatmentType === TREATMENT_TYPE.OVERBITE_UNDERBITE_CORRECTION}/>}
                    label="Overbite/underbite correction"
                  />
                  <FormControlLabel
                    value={TREATMENT_TYPE.OVERJET}
                    control={<Radio checked={treatmentType === TREATMENT_TYPE.OVERJET}/>}
                    label="Overjet"
                  />
                  <FormControlLabel
                    value={TREATMENT_TYPE.MANDIBULAR_RETROGNATHIA_PROGNATHIA}
                    control={<Radio checked={treatmentType === TREATMENT_TYPE.MANDIBULAR_RETROGNATHIA_PROGNATHIA}/>}
                    label="Mandibular retrognathia/prognathia"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {treatmentType === TREATMENT_TYPE.OVERBITE_UNDERBITE_CORRECTION && <OverbiteUnderbiteQuestions
              upperToothShowing={upperToothShowing}
              setUpperToothShowing={setUpperToothShowing}
              incisorOverlap={incisorOverlap}
              setIncisorOverlap={setIncisorOverlap}
            />}
            {treatmentType === TREATMENT_TYPE.OVERJET && <OverjetQuestions
              overjetDistance={overjetDistance}
              setOverjetDistance={setOverjetDistance}
              facialMandibularAngle={facialMandibularAngle}
              setFacialMandibularAngle={setFacialMandibularAngle}
            />}
            {treatmentType === TREATMENT_TYPE.MANDIBULAR_RETROGNATHIA_PROGNATHIA && <MandibularQuestions
              stPgToVerticalDistance={stPgToVerticalDistance}
              setStPgToVerticalDistance={setStPgToVerticalDistance}
              facialMandibularAngle={facialMandibularAngle}
              setFacialMandibularAngle={setFacialMandibularAngle}
              isSurgeryNeeded={isSurgeryNeeded}
              setIsSurgeryNeeded={setIsSurgeryNeeded}
            />}
            <Grid xs={12}>
              <Button variant="outlined" onClick={handleSubmit}>Get Aligner Checklist</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>
      {showChecklist && currentChecklist !== 0 && <AlignerChecklistDescription alignerChecklist={currentChecklist}/>}
    </Grid>
  );
}

import * as React from 'react';
import { default as MaterialAppBar } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

export default function AppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <MaterialAppBar position="static">
        <Toolbar>
          <Typography
            variant="h6"
            component={Link}
            to="/"
            sx={{ flexGrow: 1, color: 'inherit', textDecoration: 'none' }}
          >
            Home
          </Typography>
        </Toolbar>
      </MaterialAppBar>
    </Box>
  );
}

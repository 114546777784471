import * as React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { getChecklistById } from '../aligner-checklists';

export const AlignerChecklistDescription = ({ alignerChecklist }) => {
  const checklist = getChecklistById(alignerChecklist);
  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Typography variant="h5" component="div">
            Your proposed Tx Plan is #{checklist.id}
          </Typography>
        </CardContent>
        <CardActions>
          <Button component={Link} size="small" to={checklist.route}>Learn More</Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { lazy, Suspense } from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Routes, Route } from "react-router-dom";

import { Home } from './Home/Home';
import { ALIGNER_CHECKLISTS } from './aligner-checklists';
import AppBar from './AppBar/AppBar';

function App() {
  return (
    <div className="App">
      <CssBaseline/>
      <Container maxWidth="sm">
        <Grid container>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <AppBar />
          </Grid>
          <Grid item xs={12}>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route index element={<Home/>}/>
                {ALIGNER_CHECKLISTS.map(({ id, route }) => {
                    const Component = lazy(() => import((`./Checklists/Checklist${id}/Checklist${id}`)));
                    return <Route path={route} element={<Component/>} key={id}/>
                  })}
              </Routes>
            </Suspense>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box
            sx={{
              py: 3,
              px: 2,
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[200]
                  : theme.palette.grey[800],
            }}
          >
            <Typography variant="body1">
              &copy;Dr. John C Voudouris. {new Date().getFullYear()}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              The checklists are copyright protected. All rights reserved. No copy or copies in either printed or electronic form, in whole or in part, may be made without expressed written consent of Dr. John C Voudouris.
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Special thanks to Rocky Mountain Orthodontics&reg; for their support.
            </Typography>
          </Box>
        </Grid>
      </Container>
    </div>
  );
}

export default App;

import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Unstable_Grid2';

import { INCISOR_OVERLAP, UPPER_TOOTH_SHOWING } from './input-values';


export const OverbiteUnderbiteQuestions =
  ({ upperToothShowing, setUpperToothShowing, incisorOverlap, setIncisorOverlap }) => {
    return (
      <>
        <Grid xs={12}>
          <FormControl>
            <FormLabel id="upper-tooth-showing">How much of the upper tooth is showing?</FormLabel>
            <RadioGroup
              aria-labelledby="upper-tooth-showing"
              onChange={(event) => setUpperToothShowing(event.target.value)}
            >
              <FormControlLabel value={UPPER_TOOTH_SHOWING.LTE_100_GT_75} control={<Radio checked={upperToothShowing === UPPER_TOOTH_SHOWING.LTE_100_GT_75} />} label="75% < x ≤ 100% (e.g. 90%)"/>
              <FormControlLabel value={UPPER_TOOTH_SHOWING.LTE_75_GT_50} control={<Radio checked={upperToothShowing === UPPER_TOOTH_SHOWING.LTE_75_GT_50} />} label="50% < x ≤ 75% (e.g. 60%)"/>
              <FormControlLabel value={UPPER_TOOTH_SHOWING.LTE_50} control={<Radio checked={upperToothShowing === UPPER_TOOTH_SHOWING.LTE_50} />} label="x < 50% (e.g. 25%)"/>
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <FormControl>
            <FormLabel id="incisor-overlap">What is the incisor overlap?</FormLabel>
            <RadioGroup
              aria-labelledby="incisor-overlap"
              onChange={(event) => setIncisorOverlap(event.target.value)}
            >
              <FormControlLabel value={INCISOR_OVERLAP.LTE_50_GT_10} control={<Radio checked={incisorOverlap === INCISOR_OVERLAP.LTE_50_GT_10} />} label="10% < x ≤ 50% (e.g. 30%)"/>
              <FormControlLabel value={INCISOR_OVERLAP.GT_50} control={<Radio checked={incisorOverlap === INCISOR_OVERLAP.GT_50} />} label="x > 50% (e.g. 60%)"/>
              <FormControlLabel value={INCISOR_OVERLAP.LTE_10_GT_N10} control={<Radio checked={incisorOverlap === INCISOR_OVERLAP.LTE_10_GT_N10} />} label="-10% < x ≤ 10% (e.g. -5%)"/>
              <FormControlLabel value={INCISOR_OVERLAP.LTE_N10} control={<Radio checked={incisorOverlap === INCISOR_OVERLAP.LTE_N10} />} label="x < -10% (e.g. -20%)"/>
            </RadioGroup>
          </FormControl>
        </Grid>
      </>
    )
  };